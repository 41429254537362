import moment from 'moment/dist/moment';
import { format, getTime, formatDistanceToNow, isDate, parse } from 'date-fns';

// ----------------------------------------------------------------------

export function fFromApi(date) {
  return date ? moment(date, 'DD/MM/YYYY').format('MM/DD/YYYY') : '';
}

export function fDate(date) {
  return date ? format(new Date(date), 'dd MMMM yyyy') : '';
}

export function fDateDash(date) {
  return format(new Date(date), 'yyyy-MM-dd');
}

export function fDateTime(date) {
  return format(new Date(date), 'dd MMM yyyy HH:mm');
}

export function fDateTimeHSuffix(date) {
  return date ? format(new Date(date), 'dd MMM yyyy p') : '';
}

export function fDateTimeHMSuffix(date) {
  return format(new Date(date), 'MMM dd yyyy p');
}

export function fTimestamp(date) {
  return getTime(new Date(date));
}

export function fDateTimeSuffix(date) {
  return format(new Date(date), 'dd/MM/yyyy hh:mm p');
}

export function fDateNumber(date) {
  return format(new Date(date), 'dd/MM/yyyy');
}

export function fDateNumberMonth(date) {
  return format(new Date(date), 'MM/dd');
}

export function fDateToShortMonth(date) {
  return format(new Date(date), 'MMM dd');
}

export function fToNow(date) {
  return moment(date).fromNow();
}

export function fTime(date) {
  return format(new Date(date), 'HH:mm:ss');
}

export function fTimeNow(timezone = Intl.DateTimeFormat().resolvedOptions().timeZone) {
  const timeToTz = new Date().toLocaleString('en-US', { timeZone: timezone });
  return format(new Date(timeToTz), 'HH:mm:ss');
}

export function fDateDashedNow(timezone = Intl.DateTimeFormat().resolvedOptions().timeZone) {
  const timeToTz = new Date().toLocaleString('en-US', { timeZone: timezone });
  return format(new Date(timeToTz), 'yyyy-MM-dd');
}

export function convertTo12hr(time24hr) {
  // Parse the input time string
  const parsedTime = parse(time24hr, 'HH:mm:ss', new Date());

  // Check if parsing was successful
  if (!isDate(parsedTime)) {
    throw new Error('Invalid time format');
  }

  // Format the parsed time in 12-hour format
  const time12hr = format(parsedTime, 'h:mm a');

  return time12hr;
}
