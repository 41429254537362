import React from 'react';
import { Grid, LinearProgress, Typography } from '@mui/material';
import useLocales from '../hooks/useLocales';

const LinearLoader = () => {
  const { translate } = useLocales();

  return (
    <Grid container spacing={2} justifyContent={'center'}>
      <Grid item xs={10}>
        <Typography variant="h4" sx={{ textAlign: 'center', fontSize: '.26rem', color: '#333' }}>
          {translate('loading_please_wait')}
        </Typography>
        <LinearProgress />
      </Grid>
    </Grid>
  );
};

export default LinearLoader;
