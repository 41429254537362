import React, { useState, useEffect } from 'react';
import { Autocomplete, Button, CardContent, InputAdornment, TextField, Typography } from '@mui/material';
import parsePhoneNumberFromString from 'libphonenumber-js';
import { countries } from '../_mock';
import Iconify from './Iconify';
import CustomModal from './CustomModal';
import { DEFAULT_COUNTRY_SELECTIONS, IS_DEV } from '../config';
import useLocales from '../hooks/useLocales';

const getCountryCodeObj = (code) => {
  if (!code) return null;
  let countryCode = null;
  if (code) {
    const foundCode = countries.find((x) => x?.code === code);
    if (foundCode) countryCode = foundCode;
  }

  return countryCode;
};

const PhonenumberTextField = ({
  onChange,
  value,
  showCountrySelections = true,
  isValidPhone = () => {},
  ...others
}) => {
  const [country, setCountry] = useState(null);
  const [textFieldValue, setTextFieldValue] = useState(value || '');

  const { translate } = useLocales();

  const [openCodeSelect, setOpenCodeSelect] = useState(false);

  useEffect(() => {
    const _country = getCountryCodeObj(process.env.REACT_APP_COUNTRY_CODE);

    if (_country) setCountry(_country);
  }, []);

  useEffect(() => {
    if (value) {
      const phoneNumber = parsePhoneNumberFromString(value, country?.code);

      if (phoneNumber) {
        if (IS_DEV) isValidPhone(phoneNumber.isPossible());
        else isValidPhone(phoneNumber.isValid());

        const _country = countries.find((c) => c.phone === phoneNumber.countryCallingCode);
        if (_country && _country.code !== country?.code) {
          setCountry(_country);
        }
        setTextFieldValue(phoneNumber.nationalNumber);
      } else {
        isValidPhone(false);
        setTextFieldValue(value);
      }
    } else {
      isValidPhone(false);
      setTextFieldValue(value);
    }
  }, [value, country]);

  const handleCountryChange = (country) => {
    handleCloseMenu();
    setCountry(country);
    if (country) {
      onChange(``);
    }
  };

  const handleTextFieldChange = (event) => {
    const phone = event.target.value;
    const phoneNumber = parsePhoneNumberFromString(phone, country?.code);

    if (phoneNumber) {
      onChange(phoneNumber.number);
    } else {
      onChange(phone);
    }
  };

  // const [openMenu, setOpenMenuActions] = useState(null);

  // const handleOpenMenu = (event) => {
  //   setOpenMenuActions(event.currentTarget);
  // };

  // const handleCloseMenu = () => {
  //   setOpenMenuActions(null);
  // };

  const handleOpenMenu = () => {
    setOpenCodeSelect(true);
  };

  const handleCloseMenu = () => {
    setOpenCodeSelect(false);
  };

  return (
    <>
      <TextField
        value={textFieldValue}
        size="small"
        sx={{
          position: 'relative',
          color: '#2b3990 !important',
          height: '.71rem',
          input: {
            height: 'unset !important',
          },
        }}
        InputProps={{
          startAdornment: showCountrySelections ? (
            <InputAdornment position="start" sx={{ position: 'relative', color: 'grey' }}>
              <Button
                variant="outlined"
                size="small"
                onClick={handleOpenMenu}
                sx={{ m: 0, minWidth: 'unset' }}
                endIcon={<Iconify icon={'mingcute:down-fill'} />}
                tabIndex={-1}
              >
                <Typography sx={{ fontWeight: 700, fontSize: '.25rem', color: '#2b3990', lineHeight: 'normal' }}>
                  {country?.code}
                </Typography>
              </Button>
              <Typography sx={{ fontWeight: 700, fontSize: '.25rem', color: '#2b3990', lineHeight: 'normal', ml: 1 }}>
                +{country?.phone}
              </Typography>
            </InputAdornment>
          ) : (
            ''
          ),
          sx: {
            width: '100%',
            padding: '0 .15rem',
            height: '.71rem',
            background: '#f9f9f9',
            border: '1px solid #c8c8c8',
            borderRadius: '.1536rem',
            alignItems: 'center',
            fontSize: '.25rem',
            position: 'relative',
            color: 'grey !important',
            '& input': {
              color: '#2b3990 !important',
              fontSize: '.25rem',
              lineHeight: '.25rem',
              fontWeight: 700,
            },
            '&::placeholder': {
              opacity: 1,
              color: '#8f99ac',
            },
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: 'transparent !important',
            },
          },
        }}
        {...others}
        onChange={handleTextFieldChange}
      />
      <CustomModal
        fullWidth
        maxWidth="xs"
        title={translate('Select Country Code')}
        open={openCodeSelect}
        onClose={handleCloseMenu}
        sx={{ border: '1px solid grey', fontSize: '.25rem !important' }}
      >
        <CardContent>
          <Autocomplete
            // disablePortal
            fullWidth
            value={country}
            options={
              DEFAULT_COUNTRY_SELECTIONS && DEFAULT_COUNTRY_SELECTIONS?.length
                ? countries.filter((x) => DEFAULT_COUNTRY_SELECTIONS.includes(x?.code))
                : countries
            }
            getOptionLabel={(option) => `${option.label} +${option.phone}`}
            onChange={(e, v) => handleCountryChange(v)}
            renderInput={(params) => <TextField fullWidth {...params} />}
            sx={{ 
              '& .MuiOutlinedInput-root': {
                color: '#333',

                  '& fieldset': {
                    color: '#333',
                    borderColor: '#333',
                  },
                  '&:hover fieldset': {
                    color: '#333',
                    borderColor: '#333',
                  },
                  '&.Mui-focused fieldset': {
                    color: '#333',
                    borderColor: '#333',
                  },
                },
                '& .MuiAutocomplete-listbox': {
                  backgroundColor: 'rgb(18, 18, 18)',
                  backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.16))',
                },
             }}
            ListboxProps={{
              style: {
                backgroundColor: 'rgb(18, 18, 18)',
                backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.16))',
              },
            }}
          />
        </CardContent>
      </CustomModal>
    </>
  );
};

PhonenumberTextField.propTypes = {};

export default PhonenumberTextField;
