// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const bn = {
  game: `খেলা`,
  games: `গেমস`,
  sport: `খেলা`,
  center: `কেন্দ্র`,
  lucky_gift: `ভাগ্যবান উপহার`,
  all_games: `সব খেলা`,
  game_history: `খেলার ইতিহাস`,
  coupon_code: `কুপন কোড`,
  telegram: `টেলিগ্রাম`,
  customer_service: `গ্রাহক সেবা`,
  player: `প্লেয়ার`,
  winner: `বিজয়ী`,
  all_bets: `সব বাজি`,
  my_bets: `আমার বাজি`,
  name: `নাম`,
  time: `সময়`,
  bet_amount: `বাজির পরিমাণ`,
  prediction: `ভবিষ্যদ্বাণী`,
  payout: `পেআউট`,
  login: `প্রবেশ করুন`,
  register: `নিবন্ধন`,
  username_placeholder: `ব্যবহারকারীর নাম`,
  please_input_your_x: `অনুগ্রহ করে আপনার {{x}} ইনপুট করুন`,
  captcha: `CAPTCHA`,
  forgot_password: `পাসওয়ার্ড ভুলে গেছেন`,
  minor_note: `এই সাইট পরিদর্শন করতে, নিশ্চিত করুন যে আপনি 18 বছরের বেশি এবং সম্মত হন`,
  terms_of_service: `সেবা পাবার শর্ত`,
  by_signing_in: 'সাইন ইন বা সাইন আপ করে, আপনি আমাদের সাথে সম্মত হন',
  dont_have_account: `একটি অ্যাকাউন্ট নেই?`,
  sign_up: `নিবন্ধন করুন`,
  username: `ব্যবহারকারীর নাম`,
  email: `ইমেইল`,
  full_name: `পুরো নাম`,
  confirm_18: `আমি নিশ্চিত যে আমার বয়স 18 বছর এবং আমি পড়েছি`,
  already_have_account: `ইতিমধ্যে একটি সদস্যপদ আছে?`,
  share: `শেয়ার করুন`,
  deposit: `জমা`,
  bonus: `বোনাস`,
  app: `অ্যাপ`,
  load_more: `আর ঢুকাও`,
  details: `বিস্তারিত`,
  helps: `সাহায্য করে`,
  kyc_policy: `কেওয়াইসি নীতি`,
  privacy_policy: `গোপনীয়তা নীতি`,
  responsible_gaming: `দায়িত্বশীল গেমিং`,
  terms_and_condition: `শর্তাবলী`,
  faq: `FAQ`,
  about_us: `আমাদের সম্পর্কে`,
  amount: `পরিমাণ`,
  extra: `অতিরিক্ত`,
  deposit_bonus: `ডিপোজিট বোনাস`,
  do_not_participate: `প্রচারে অংশগ্রহণ করবেন না`,
  payment_problem: `পেমেন্ট সমস্যা`,
  click_for_help: `সাহায্যের জন্য ক্লিক করুন`,
  deposit_notes: `জমা নোট`,
  available_balance: `পর্যাপ্ত টাকা`,
  withdraw: `প্রত্যাহার করুন`,
  withdrawal_notes: `প্রত্যাহার নোট`,
  withdrawal_note1: `বর্তমান সময়ে প্রত্যাহারের সংখ্যা`,
  withdrawal_note1_1: `ভিআইপি`,
  withdrawal_note2: `প্রত্যাহার ফি:`,
  min: `মিন:`,
  max: `সর্বোচ্চ:`,
  opening_times: `খোলার সময়:`,
  withdrawal_note3: `1.10 মিনিটের মধ্যে দ্রুততম প্রত্যাহারের সময়`,
  withdrawal_note4: `2.আপনার প্রত্যাহারের তথ্য সঠিক কিনা তা নিশ্চিত করুন, যদি প্রত্যাহারের তথ্য ভুল হয়, তাহলে এটি আপনার তহবিল হারাতে পারে।`,
  withdrawal_note5: `3.Please feel free to contact us if you have any questions.`,
  notifications: `বিজ্ঞপ্তি`,
  invalid_x: `অবৈধ {{x}}`,
  captcha_not_match: `Captcha মেলে না`,
  invalid_x_must_be_atleast_y_characters: `অবৈধ {{x}}, কমপক্ষে {{y}} অক্ষর হতে হবে৷`,
  x_is_required: `{{x}} প্রয়োজন৷`,
  phone_number: 'ফোন নম্বর',
  phone_num_x_max: `ফোন নম্বর সর্বাধিক {{x}} সংখ্যা`,
  email_sent: `ইমেল পাঠানো হয়েছে, আপনার ইমেল চেক করুন.`,
  enter_valid_email: `একটি বৈধ ইমেইল প্রবেশ করুন।`,
  confirm: `নিশ্চিত করুন`,
  password_atleast_6_characters: `পাসওয়ার্ড কমপক্ষে 6 অক্ষরের হতে হবে।`,
  password_reset_success: `পাসওয়ার্ড রিসেট সফল!.`,
  new_password: `New Password`,
  bank: 'ব্যাংক',
  bank_transfer: `ব্যাংক লেনদেন`,
  bank_name: `ব্যাংকের নাম`,
  account_name: `হিসাবের নাম`,
  account_number: `হিসাব নাম্বার`,
  drop_or_select: `ড্রপ বা সিলেক্ট ফাইল`,
  drop_files_here: `এখানে ফাইল ড্রপ`,
  transfer_to: `স্থানান্তর করা`,
  upload_receipt: `রসিদ আপলোড করুন`,
  select_bonus: `বোনাস নির্বাচন করুন`,
  no_promotion: `কোন প্রচার নেই`,
  please_upload_receipt: `অনুগ্রহ করে রসিদ আপলোড করুন`,
  bank_account: `ব্যাংক হিসাব`,
  add_account: `হিসাব যোগ করা`,
  account_holder_name: `হিসাব দাতার নাম`,
  name_of_the_account_holder: `অ্যাকাউন্ট হোল্ডারের নাম`,
  fill_in_bank_account: `ব্যাঙ্ক অ্যাকাউন্ট পূরণ করুন`,
  withdraw_information: `তথ্য প্রত্যাহার`,
  note: `বিঃদ্রঃ`,
  amount_withdrawn: `টাকা প্রত্যাহার করা হয়েছে`,
  amount_no_exceed_balance: `প্রত্যাহারের পরিমাণ উপলব্ধ ব্যালেন্সের বেশি হওয়া উচিত নয়`,
  minimum_withdraw_amount_is_x: `সর্বনিম্ন উত্তোলনের পরিমাণ হল {{x}}`,
  transaction_history: `আপনি কি আমার সাথে কি করতে চান`,
  date_from: `তারিখ থেকে`,
  date_to: `তারিখ`,
  account_name_must_be_4: `অ্যাকাউন্টের নাম দৈর্ঘ্যে 4-এর বেশি হতে হবে`,
  account_number_must_be_x: `অ্যাকাউন্ট নম্বর দৈর্ঘ্যে 10-14 হতে হবে`,
  minimum_length_is_x: `সর্বনিম্ন দৈর্ঘ্য হল {{x}}`,
  maximum_length_is_x: `সর্বাধিক দৈর্ঘ্য হল {{x}}`,
  payment_method: `Payment Method`,
  mode: `মোড`,
  no_bank_account: `প্রত্যাহার করার জন্য আপনার কোনো অ্যাকাউন্ট নেই, এখনই যোগ করুন`,
  please_select_bank: `একটি ব্যাঙ্ক অ্যাকাউন্ট নির্বাচন করুন`,

  bet_history: `বাজি ইতিহাস`,
  game_provider: `গেম প্রদানকারী`,
  bet_count: `বাজি গণনা`,
  win_loss: `জয়/পরাজয়`,
  rebate: `রিবেট`,
  turnover: `টার্নওভার`,
  game_category: `খেলা বিভাগ`,

  you_have_x_unread_messages: `আপনার {{x}}টি অপঠিত বার্তা আছে৷`,
  deposit_amount_between_x_and_y: `জমার পরিমাণ অবশ্যই {{x}} এবং {{y}} এর মধ্যে হতে হবে`,
  minimum_amount_is_x_y: `সর্বনিম্ন পরিমাণ হল {{x}}{{y}}`,

  // all
  all: `সব`,
  title: `শিরোনাম`,
  date: `তারিখ`,
  type: `টাইপ`,
  status: `স্ট্যাটাস`,
  remark: `মন্তব্য`,
  category: `শ্রেণী`,
  please_select_x: `অনুগ্রহ করে {{x}} নির্বাচন করুন`,
  save: `সংরক্ষণ`,

  change_password: `পাসওয়ার্ড পরিবর্তন করুন`,
  current_password: `বর্তমান পাসওয়ার্ড`,
  confirm_password: `নিশ্চিত কর নতুন গোপননম্বর`,
  submit: `জমা দিন`,
  password_changed: `পাসওয়ার্ড পরিবর্তন`,
  x_must_be_y_characters: `{{x}} কমপক্ষে {{y}} অক্ষর হতে হবে`,
  reset_password: `পাসওয়ার্ড রিসেট করুন`,
  casino: `ক্যাসিনো`,
  slots: `স্লট`,
  live_casino: `লাইভ ক্যাসিনো`,
  fishing: `মাছ ধরা`,
  sports: `খেলাধুলা`,
  cards: `তাস`,
  clear: `পরিষ্কার`,
  search: `অনুসন্ধান করুন`,
  new: `নতুন`,
  logout: `প্রস্থান`,
  choose_date: `তারিখ নির্বাচন করুন`,
  select_both_date: `তারিখ থেকে এবং থেকে উভয়ই নির্বাচন করুন`,
  from: `থেকে`,
  to: `প্রতি`,
  apply: `আবেদন করুন`,
  promotions: `প্রচার`,
  learn_more: `আরও জানুন`,
  password_doesnt_match: `পাসওয়ার্ড মেলে না`,
  cancel: `বাতিল করুন`,
  confirm_to_pay: `পরিশোধ করতে নিশ্চিত করুন`,
  deposit_amount: `আমানত পরিমাণ`,
  loading_please_wait: `প্রেরণ করা হচ্ছে, অনুগ্রহ করে অপেক্ষা করুন`,
  account: `হিসাব`,
  profile: `প্রোফাইল`,
  bank_list: `ব্যাংক তালিকা`,
  in_maintenance: `রক্ষণাবেক্ষণে`,
  birthday: `জন্মদিন`,
  birthday_bonus: `বোনাসের জন্য আপনার জন্মদিন লিখুন! 🎉🎁`,
  referral_code: `রেফারেল কোড`,
  referral: `সুপারিশ`,
  my_referral: `আমার রেফারেল`,
  my_claim: `আমার দাবি`,
  claim_profit: `লাভ দাবি`,
  available_profit: `উপলব্ধ লাভ`,
  claimed_profit: `দাবিকৃত লাভ`,
  all_time_profit: `সর্বকালের লাভ`,
  referral_note_on_x: `প্রতিবার আপনার বন্ধুরা {{x}} এ খেলে লাভ উপার্জন করুন`,
  tier: `স্তর`,
  commission: `কমিশন`,
  referred_member: `উল্লেখিত সদস্য`,
  profit_earned: `লাভ অর্জিত`,
  account_information: `হিসাবের তথ্য`,
  invite_friends: `Invite Friends`,
  how_it_work: `এটি কিভাবে কাজ করে?`,
  invite_note: `আপনার বন্ধু আপনার রেফারেল কোড ব্যবহার করে সাইন আপ করলে একচেটিয়া রেফারেল বোনাস পান!`,
  share_via: `এর মাধ্যমে শেয়ার করুন`,
  referral_link: `আমার স্নাতকের`,
  total_profit: `সমস্ত লাভ`,
  copied: `কপি করা হয়েছে`,
  nothing_to_claim: `দাবি করার কিছুই নেই`,
  claim_history: `দাবি ইতিহাস`,
  downline: `আমার ডাউনলাইন`,
  last_x_digit_bank_ref_number: `ব্যাঙ্ক রেফ নম্বরের শেষ {{x}} সংখ্যা`,
  bank_ref_number_validation_x: `ব্যাঙ্ক রেফ নম্বর প্রয়োজন এবং অবশ্যই {{x}} অক্ষর দৈর্ঘ্য হতে হবে৷`,
  sort: 'সাজান',

  refer_and_reward: `উল্লেখ করুন এবং পুরস্কার`,
  linked_successfully_x: `{{x}} এর সাথে সফলভাবে লিঙ্ক করা হয়েছে! প্রথমবার আপনার অ্যাকাউন্ট নিবন্ধন করুন`,
  more_x_of_y: `আরও {{x}}/{{y}}`,
  default: `ডিফল্ট`,
  promotion: `পদোন্নতি`,
  daily_mission: 'দৈনিক মিশন',
  agent: 'প্রতিনিধি',
  contact_us: 'যোগাযোগ করুন',
  download: 'ডাউনলোড করুন',
  cash_claim: 'নগদ দাবি',
  favorites: 'প্রিয়',
  popular: 'জনপ্রিয়',
  claim_bonus: `বোনাস দাবি করুন`,
  insufficient_balance: `অপর্যাপ্ত টাকা`,
  currently_displaying: 'বর্তমানে {{z}} এর মধ্যে {{x}} {{y}}টি গেম প্রদর্শন করা হচ্ছে',
  all_x: 'সমস্ত {{x}}',
  no_results: 'কোন ফলাফল পাওয়া যায়নি',
  search_games: 'গেম অনুসন্ধান করুন',
  please_scan_using_your: `আপনার ব্যবহার করে স্ক্যান করুন`,
  vip_level: `ভিআইপি লেভেল`,
  bonus_direct: `সরাসরি বোনাস`,
  rebate_level: `রিবেট লেভেল`,
  vip_header: `HalongBay-এ স্লট মেশিন, বোর্ড এবং কার্ড গেমস, ফিশিং, ভিডিও, স্পোর্টস এবং লটারি গেমগুলিতে রাখা সমস্ত বাজি স্থায়ীভাবে জমা হবে৷ বৈধ বাজি = স্থায়ী আয় এবং অর্থ ভাগ্য!`,
  vip_example: `
  Example 1: Members who reach 1 Million in accumulated deposits, reach VIP Level 2, receive a bonus of R$210,and Rebate 3.50%, When they reach 6 Million in accumulated deposit, they reach VIP Level 3 and receive a bonus of R$800, with a Rebate 4%`,
  activity_rules: `III. Activity Rules:`,
  activity_rules_1: `1. It is necessary for all bonuses to have x3 Winover to request the Withdrawal`,
  activity_rules_2: `2. Please claim the Rebate Bonus within the deadline, if you happen to be late, it will be considered abandonment;`,
  activity_rules_3: `3. Members' participation in activities is automatically counted by the system, if there is any objection, the result of Brasil7777's investigation will prevail;`,
  activity_rules_4: `4. If you forget your member Account/Password, please contact "24-hour Online Customer Service" to help you recover your account information;`,
  activity_rules_5: `5. Brasil7777 reserves the right to change, stop or cancel this promotion at any time.`,
  activity_rules_6: `6. Do not save free game , Buy free game`,
  activity_rules_7: `7. Can't combine with any bonus`,
  activity_rules_8: `8. Maximum Withdraw per Deposit is R$100,000`,
  member: `সদস্য`,
  return_home: `বাড়িতে ফিরে`,
  more: `আরও`,
  earn: `আয় করুন`,
  deposit_method: `জমা পদ্ধতি`,
  next: `পরবর্তী`,
  my_account: `আমার অ্যাকাউন্ট`,
  level: `স্তর`,
  providers: `প্রদানকারী`,
  home: `বাড়ি`,
  member_center: `সদস্য কেন্দ্র`,
  adjustment: `সামঞ্জস্য`,
  no_balance: `ব্যালেন্স নেই`,
  withdraw_amount: `পরিমাণ প্রত্যাহার`,
};

export default bn;
