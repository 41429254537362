/* eslint-disable camelcase */
// @mui
import { useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Container, Grid, Stack, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { getYear } from 'date-fns';
import Iconify from '../../components/Iconify';
import Image from '../../components/Image';
import { useSelector } from '../../redux/store';
import useLocales from '../../hooks/useLocales';
import palette from '../../theme/palette';

const StyledTypography = styled(Typography)(({ theme }) => ({
  '&': {
    color: 'white',
    fontSize: '12px',
    fontWeight: 600,
    textTransform: 'uppercase',
    marginTop: '2rem',
  },
}));

const FooterText = styled(Typography)(({ theme }) => ({
  color: 'white',
  fontWeight: 400,
  fontSize: '.75rem',
}));

const Footer = () => {
  const { siteSettings } = useSelector((x) => x.lookup);

  const { enqueueSnackbar } = useSnackbar();

  const { translate } = useLocales();

  const {
    config_footer_copyright,
    config_phonenumber,
    // config_telegram,
    // config_wechat,
    // config_whatsapp,
    // config_facebook,
    // config_messenger,
    // config_viber,
    // config_twitter,
    // config_instagram,
    // config_youtube,
    // config_line,
  } = siteSettings || {};

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    enqueueSnackbar('Copied!');
  };

  const copyrightText = useMemo(() => {
    if (siteSettings?.config_footer_copyright) {
      const newText = siteSettings?.config_footer_copyright
        .replace('[year]', getYear(new Date()))
        .replace('[name]', siteSettings?.config_name);

      console.log(newText);

      return newText;
    }

    return '';
  }, [siteSettings?.config_footer_copyright, siteSettings?.config_name]);

  const openSocial = (link) => {
    window.open(
      link,
      '_blank'
      // 'noopener noreferrer'
    );
  };

  return (
    <Box sx={{ m: '20px auto 0', p: '20px 0px 100px', overflow: 'hidden' }}>
      {/* Player/Winner */}
      {/* <Grid container spacing={1}>
        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <Box sx={{ backgroundColor: '#343a45', borderRadius: '10px', p: '20px', textAlign: 'center' }}>
            <Stack direction="column" alignItems={'center'} justifyContent={'center'}>
              <Typography sx={{ fontSize: '24px', color: '#437bde', fontWeight: 'bolder' }}>915, 190</Typography>
              <Stack direction="row" justifyContent={'center'} alignItems={'center'} spacing={1}>
                <Iconify icon={'fa:group'} width={25} height={25} sx={{ color: 'rgb(152, 172, 208)' }} />
                <Typography sx={{ fontSize: '14px', color: '#98acd0', fontWeight: 'bolder' }}>Player</Typography>
              </Stack>
            </Stack>
          </Box>
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <Box sx={{ backgroundColor: '#343a45', borderRadius: '10px', p: '20px', textAlign: 'center' }}>
            <Stack direction="column" alignItems={'center'}>
              <Typography sx={{ fontSize: '24px', color: '#437bde', fontWeight: 'bolder' }}>970, 957</Typography>
              <Stack direction="row" justifyContent={'center'} alignItems={'center'} spacing={1}>
                <Iconify
                  icon={'healthicons:award-trophy'}
                  width={25}
                  height={25}
                  sx={{ color: 'rgb(152, 172, 208)' }}
                />
                <Typography sx={{ fontSize: '14px', color: '#98acd0', fontWeight: 'bolder' }}>Player</Typography>
              </Stack>
            </Stack>
          </Box>
        </Grid>
      </Grid> */}

      <Container maxWidth="lg" sx={{ backgroundColor: palette.footer.bgColor, p: '35px 0px !important' }}>
        <Stack direction="row" alignItems={'center'} spacing={2} sx={{ ml: 2 }}>
          <Iconify icon={'fa-solid:dice'} sx={{ width: '25px', height: '25px' }} color="#fff" />
          <Typography sx={{ color: 'white', fontWeight: 600, fontSize: '.85rem' }}>
            {translate('our_game_provides')}
          </Typography>
        </Stack>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mt: 2, px: 2 }}>
          <Grid container spacing={3} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {_gameProviders.map((item, index) => (
              <Grid key={index} item>
                <Image
                  src={item}
                  alt="Google"
                  sx={{
                    height: { xl: '40px', lg: '40px', md: '40px', sm: 'auot', xs: 'auto' },
                    width: { xl: 'auto', lg: 'auto', md: 'auto', sm: '100%', xs: '100%' },
                    maxWidth: '130px',
                    '& img': {
                      objectFit: 'contain',
                    },
                  }}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
      <Container maxWidth="lg" sx={{ p: 2 }}>
        <Grid container spacing={{ sm: 1, xs: 2 }}>
          <Grid item sm={12 / 5} xs={12 / 2}>
            <Stack direction={'column'} spacing={1}>
              <Image
                src={require('../../assets/brand-logo.png')}
                sx={{
                  height: '40px',
                  width: 'auto',
                  maxWidth: '130px',
                  '& img': {
                    objectFit: 'contain',
                  },
                }}
              />
            </Stack>
          </Grid>
          <Grid item sm={12 / 5} xs={12 / 2}>
            <Stack direction={'column'} spacing={1}>
              <FooterText sx={{ fontWeight: 600 }}>HELPFUL LINKS</FooterText>
              <FooterText>All Games</FooterText>
              <FooterText>Slots</FooterText>
              <FooterText>Live Casino</FooterText>
            </Stack>
          </Grid>
          <Grid item sm={12 / 5} xs={12 / 2}>
            <Stack direction={'column'} spacing={1}>
              <FooterText sx={{ fontWeight: 600 }}>PLATFORM</FooterText>
              <FooterText>Support</FooterText>
              <FooterText>FAQ</FooterText>
              <FooterText>Slots</FooterText>
              <FooterText>Partnetship Program</FooterText>
              <FooterText>Help Center</FooterText>
            </Stack>
          </Grid>
          <Grid item sm={12 / 5} xs={12 / 2}>
            <Stack direction={'column'} spacing={1}>
              <FooterText sx={{ fontWeight: 600 }}>ABOUT US</FooterText>
              <FooterText>AML Policy</FooterText>
              <FooterText>Sports Policy</FooterText>
              <FooterText>Responsible Gaming</FooterText>
              <FooterText>Privacy Policy</FooterText>
              <FooterText>Terms and Conditions</FooterText>
            </Stack>
          </Grid>
          <Grid item sm={12 / 5} xs={12 / 2}>
            <Stack direction={'column'} spacing={1}>
              <FooterText sx={{ fontWeight: 600 }}>COMMUNITY</FooterText>
              <FooterText>AML Policy</FooterText>
              <FooterText>Sports Policy</FooterText>
              <FooterText>Responsible Gaming</FooterText>
              <FooterText>Privacy Policy</FooterText>
              <FooterText>Terms and Conditions</FooterText>
            </Stack>
          </Grid>
        </Grid>
      </Container>

      <Container maxWidth="lg" sx={{ p: 2, mt: '4rem' }}>
        <Box
          sx={{
            borderTop: '1.5px solid white',
            borderBottom: '1.5px solid white',
            width: 'fit-content',
            display: 'block',
            mx: 'auto',
            py: 1,
          }}
        >
          <Image
            src={require('../../assets/powered/crypto.png')}
            sx={{
              height: '40px',
              width: 'auto',
              '& img': {
                objectFit: 'contain',
              },
            }}
          />
        </Box>
        <StyledTypography sx={{ fontSize: '9px !important' }}>{config_footer_copyright}</StyledTypography>
      </Container>
    </Box>
  );
};

export default Footer;

const _gameProviders = [
  require('../../assets/brand-logo.png'),
  require('../../assets/powered/pgsoft.png'),
  require('../../assets/powered/pragmatic_play.png'),
  require('../../assets/powered/jili.png'),
  require('../../assets/powered/evolution.png'),
  require('../../assets/powered/evoplay.png'),
];
