import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

import { getSiteSettings, getUserMemberBalance } from '../../redux/slices/lookup';
import useAuth from '../../hooks/useAuth';

import { usePageVisibility } from '../../hooks/usePageVisibility';

const MainStyle = styled('main', {
  shouldForwardProp: (prop) => prop !== 'collapseClick',
})(({ collapseClick, theme }) => ({
  flexGrow: 1,
  // paddingBottom: '5rem',
  backgroundColor: '#f9f9f9',
}));

const CommonLayout = () => {
  const { isAuthenticated } = useAuth();

  const isVisible = usePageVisibility();

  useEffect(() => {
    console.log(`Enter Common Layout`);
    getSiteSettings();
  }, []);

  useEffect(() => {
    if (isVisible && isAuthenticated) getUserMemberBalance();
  }, [isVisible, isAuthenticated]);
  return (
    <Box>
      <MainStyle>
        <Outlet />
      </MainStyle>
    </Box>
  );
};

export default CommonLayout;
