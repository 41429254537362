import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { AppBar, Box, Grid, Stack, Toolbar, Typography, useScrollTrigger } from '@mui/material';
import { loadCaptchaEnginge } from 'react-simple-captcha';
// hooks
import { m } from 'framer-motion';
import useOffSetTop from '../../../hooks/useOffSetTop';
import useResponsive from '../../../hooks/useResponsive';
// utils
import cssStyles from '../../../utils/cssStyles';
// config
import { HEADER } from '../../../config';
// components
import Logo from '../../../components/Logo';
import Iconify from '../../../components/Iconify';
// import LoginSignupDialog from './visitor/LoginSignupDialog';
import ForgotPasswordDialog from './forgotpassword/ForgotPasswordDialog';
import ResetPasswordDialog from './forgotpassword/ResetPasswordDialog';
import { useDispatch, useSelector } from '../../../redux/store';

import Image from '../../../components/Image';
import useLocales from '../../../hooks/useLocales';
import { fNumber, fNumberDecimal } from '../../../utils/formatNumber';
import palette from '../../../theme/palette';
import LanguagePopover from './LanguagePopover';

// ----------------------------------------------------------------------

const RootStyle = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'scrollTrigger',
})(({ theme, scrollTrigger }) => ({
  ...(scrollTrigger ? { ...cssStyles(theme).bgBlur({ color: '#fff' }) } : { background: 'none', boxShadow: 'none' }),
  height: 55.72,
  zIndex: theme.zIndex.appBar + 1,
  transition: '0.3s ease-in-out',
  background: 'rgba(255, 255, 255, 0.2)',
  WebkitBackdropFilter: 'blue(5px)',
  backdropFilter: 'blur(5px)',
  // [theme.breakpoints.up('lg')]: {
  //   height: HEADER.DASHBOARD_DESKTOP_HEIGHT,
  //   width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH + 1}px)`,
  //   ...(isCollapse && {
  //     width: `calc(100% - ${NAVBAR.DASHBOARD_COLLAPSE_WIDTH}px)`,
  //   }),
  //   ...(isOffset && {
  //     height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
  //   }),
  //   ...(verticalLayout && {
  //     width: '100%',
  //     height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
  //     backgroundColor: theme.palette.background.default,
  //   }),
  // },
}));

// ----------------------------------------------------------------------

DashboardHeader.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onOpenSidebar: PropTypes.func,
  isCollapse: PropTypes.bool,
  verticalLayout: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
  onToggleCollapse: PropTypes.func,
};

export default function DashboardHeader({
  isOpenSidebar = false,
  onOpenSidebar,
  isCollapse = false,
  verticalLayout = false,
  // onCloseSidebar,
  onToggleCollapse,
}) {
  const isOffset = useOffSetTop(HEADER.DASHBOARD_DESKTOP_HEIGHT) && !verticalLayout;

  const isDesktop = useResponsive('up', 'lg');

  const { translate } = useLocales();

  const dispatch = useDispatch();

  const { isOpenLoginSignupDialog } = useSelector((x) => x.authPages);

  const { siteSettings } = useSelector((x) => x.lookup);

  const [openForgotPasswordDialog, setOpenForgotPasswordDialog] = useState(false);

  const [openResetPasswordDialog, setOpenResetPasswordDialog] = useState(false);

  const navigate = useNavigate();

  const scrollTrigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  useEffect(() => {
    if (localStorage.getItem('referralCode')) {
      const isVisitedWithCode = localStorage.getItem('visitedRefCode');
      const isAuthenticated = localStorage.getItem('accessToken');

      if (!isVisitedWithCode && !isAuthenticated) {
        setTimeout(() => {
          navigate('/auth/login');
        }, 1000);
      }
    }

    // Check if got resetpassword token
    if (localStorage.getItem('resetToken')) {
      setOpenResetPasswordDialog(true);
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem('accessToken')) {
      // getProfile();
    } else {
      console.log('loggedoff');
    }
  }, [localStorage.getItem('accessToken')]);

  useEffect(() => {
    if (isOpenLoginSignupDialog.open)
      setTimeout(() => {
        loadCaptchaEnginge(4);
      }, 1000);
  }, [isOpenLoginSignupDialog]);

  return (
    <RootStyle
      isCollapse={isCollapse}
      isOffset={isOffset}
      verticalLayout={verticalLayout}
      scrollTrigger={scrollTrigger}
    >
      <Toolbar
        sx={{
          minHeight: '100% !important',
          pl: { lg: 1 },
          pr: { lg: 2 },
        }}
      >
        {isDesktop && verticalLayout && <Logo sx={{ mr: 2.5 }} />}
        <Grid container sx={{ alignItems: 'center' }}>
          <Grid item sm={4} xs={4}>
            {!isDesktop && siteSettings?.config_whatsapp && (
              <Image
                onClick={() => window.open(siteSettings?.config_whatsapp, '_blank')}
                src={require('../../../assets/home/header-support.png')}
                sx={{ width: '.46rem', height: '.46rem' }}
              />
            )}

            {/* {isDesktop && (
              <Stack direction="row" alignItems={'center'}>
                <Box
                  onClick={() => onToggleCollapse()}
                  sx={{
                    background: palette.header.menuBg,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: '4px',
                    p: '5px',
                    mr: 2,
                    cursor: 'pointer',
                  }}
                >
                  <Iconify icon={'ion:menu'} color={palette.header.menu} width="25px" height="25px" />
                </Box>
                <Image
                  className="header-logo"
                  onClick={() => navigate('/home')}
                  src={require('../../../assets/brand-logo.png')}
                  alt="App Logo"
                  sx={{
                    height: '50px',
                    width: 'auto',
                    ml: 1,
                    cursor: 'pointer',
                    '& img': {
                      width: 'auto !important',
                    },
                  }}
                />
              </Stack>
            )} */}
          </Grid>
          <Grid item sm={4} xs={4}>
            <Box
              onClick={() => navigate('/home')}
              sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
              <img
                // className="header-logo"
                src={require('../../../assets/brand-logo.png')}
                alt=""
                style={{ height: '.7rem', width: 'auto', cursor: 'pointer' }}
              />
            </Box>
          </Grid>
          <Grid item sm={4} xs={4}>
            <LanguagePopover />
          </Grid>
        </Grid>

        {/* <Box sx={{ flexGrow: 1 }} /> */}

        {/* <Stack direction="row" alignItems="center" spacing={1}>
          {!localStorage.getItem('accessToken') ? (
            <>
              <button
                type="button"
                onClick={() => dispatch(openLoginSignup({ open: true, isLogin: true }))}
                style={{
                  height: 'auto',
                  lineHeight: 1.4,
                  position: 'relative',
                  textAlign: 'center',
                  background: palette.header.login_btn.bg,
                  // backgroundColor: '#2283f6',
                  color: palette.header.login_btn.color,
                  fontSize: '14px',
                  fontFamily: 'sans-serif',
                  border: `2px solid ${palette.header.login_btn.border}`,
                  padding: '10px 14px',
                  borderRadius: '10px',
                  fontWeight: 'bold',
                  cursor: 'pointer',
                }}
              >
                {translate('login')}
              </button>
              <m.button
                onClick={() => dispatch(openLoginSignup({ open: true, isLogin: false }))}
                animate={{
                  boxShadow: [
                    '0px 0px 5px 0px rgba(32,252,141,0.75)',
                    '0px 0px 15px 0px rgba(32,252,141,0.75)',
                    '0px 0px 5px 0px rgba(32,252,141,0.75)',
                  ],
                  scale: [1, 1.05, 1],
                }}
                transition={{
                  type: 'keyframes',
                  stiffness: 260,
                  damping: 20,
                  repeat: Infinity,
                  repeatDelay: 0,
                  duration: 2.5,
                }}
                style={{
                  height: 'auto',
                  lineHeight: 1.4,
                  position: 'relative',
                  textAlign: 'center',
                  background: palette.header.register_btn.bg,
                  // backgroundColor: '#dd234b',
                  color: palette.header.register_btn.color,
                  fontSize: '14px',
                  fontFamily: 'sans-serif',
                  border: `2px solid ${palette.header.register_btn.border}`,
                  padding: '10px 14px',
                  borderRadius: '10px',
                  fontWeight: 'bold',
                  cursor: 'pointer',
                }}
              >
                {translate('sign_up')}
              </m.button>
            </>
          ) : (
            <Stack direction={'row'} spacing={1} sx={{ alignItems: 'center' }}>
              <Box
                sx={{
                  display: 'flex',
                  borderRadius: '6px',
                  background: palette.header.balance_button.bg,
                }}
              >
                <Stack
                  direction="row"
                  spacing={'2px'}
                  sx={{
                    p: '5px',
                    alignItems: 'center',
                    display: 'flex',
                  }}
                >
                  <Image src={require('../../../assets/home/bitcoin.png')} sx={{ width: '20px', height: '20px' }} />
                  <Typography
                    onClick={() =>
                      dispatch(
                        openTransactionsDialog({
                          open: true,
                          isDeposit: true,
                        })
                      )
                    }
                    sx={{
                      cursor: 'pointer',
                      color: palette.header.balance_button.color,
                    }}
                  >
                    {`${memberBalance?.currencyCode && isDesktop ? memberBalance?.currencyCode : ''} ${
                      memberBalance?.displayBalance ? fNumber(memberBalance?.displayBalance) : '0'
                    }`}
                  </Typography>
                </Stack>
                <Stack
                  onClick={() =>
                    dispatch(
                      openTransactionsDialog({
                        open: true,
                        isDeposit: true,
                      })
                    )
                  }
                  direction={'row'}
                  spacing={'2px'}
                  alignItems="center"
                  sx={{ borderRadius: '6px', p: '5px', background: palette.header.deposit_btn.bg, cursor: 'pointer' }}
                >
                  <Iconify icon={'game-icons:wallet'} width={20} height={20} sx={{ color: 'white' }} />
                  <Typography
                    sx={{ color: palette.header.deposit_btn.color, display: { sm: 'unset', display: 'none' } }}
                  >
                    {translate('deposit')}
                  </Typography>
                </Stack>
              </Box>
              
              <NotificationPopover />

              <AccountPopover />
            </Stack>
          )}
        </Stack> */}

        {/* {isOpenLoginSignupDialog.open && (
          <LoginSignupDialog setOpenForgotPasswordDialog={setOpenForgotPasswordDialog} />
        )} */}

        {openForgotPasswordDialog && (
          <ForgotPasswordDialog
            openForgotPasswordDialog={openForgotPasswordDialog}
            setOpenForgotPasswordDialog={setOpenForgotPasswordDialog}
          />
        )}

        {openResetPasswordDialog && (
          <ResetPasswordDialog
            openResetPasswordDialog={openResetPasswordDialog}
            setOpenResetPasswordDialog={setOpenResetPasswordDialog}
          />
        )}
      </Toolbar>
    </RootStyle>
  );
}
