import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { IconButton, InputAdornment, TextField, InputBase } from '@mui/material';
import { useState } from 'react';
import Iconify from '../Iconify';

// ----------------------------------------------------------------------

RHFPassword.propTypes = {
  name: PropTypes.string,
};

export default function RHFPassword({ name, ...other }) {
  const { control } = useFormContext();

  const [showPassword, setShowPassword] = useState(false);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          {...other}
          fullWidth
          variant="outlined"
          error={!!error}
          helperText={error?.message}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end" tabIndex={-1}>
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
          sx={{
            '& input': {
              color: '#000',
              fontWeight: 600,
              '&::placeholder': {
                color: 'rgb(0, 0, 0, .3)',
              },
            },
          }}
        />
      )}
    />
  );
}
